import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "submit", "lengthText", "lengthIcon", "uppercaseText", "uppercaseIcon", "numberText", "numberIcon"]

  validations = { length: false, uppercase: false, number: false }

  validate (e) {
    this.validateLength(e.target.value)
    this.validateUppercase(e.target.value)
    this.validateNumber(e.target.value)

    this.submitTarget.toggleAttribute("disabled", !Object.values(this.validations).every(validation => validation))
  }

  validateLength (text) {
    this.setValid("length", text.length >= 8 && text.length <= 30)
  }

  validateUppercase (text) {
    this.setValid("uppercase", /[A-Z]/.test(text))
  }

  validateNumber (text) {
    this.setValid("number", /[0-9]/.test(text))
  }

  setValid (requirement, isValid) {
    this.validations[requirement] = isValid

    this[`${requirement}TextTarget`].classList.toggle("text-white-base", isValid)
    this[`${requirement}TextTarget`].classList.toggle("text-gray-400", !isValid)
    this[`${requirement}IconTarget`].classList.toggle("stroke-positive", isValid)
    this[`${requirement}IconTarget`].classList.toggle("stroke-gray-400", !isValid)
  }
}
