import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    type: String,
    equal: String
  }
  static targets = ["targetElement"]

  change (e) {
    switch(this.typeValue){
      case "check":
        this.targetElementTarget.disabled = (e.target.checked.toString() == this.equalValue)
        this.targetElementTarget.classList.toggle("disabled:opacity-75")
        this.targetElementTarget.classList.toggle("cursor-not-allowed")
        break
      default:
        this.targetElementTarget.disabled = (e.target.value == this.equalValue)
        this.targetElementTarget.classList.toggle("disabled:opacity-75")
        this.targetElementTarget.classList.toggle("cursor-not-allowed")
        break
    }
  }

}
