import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  change (e) {
    const searchParams = new URLSearchParams()

    searchParams.set("locale", e.target.value)

    window.location.search = searchParams.toString()
  }
}
