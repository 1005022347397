import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    this.checkboxTargets.forEach((checkboxTarget)=>{
      this.toggleBackgroundClass(checkboxTarget.parentElement, checkboxTarget.checked)
    })
  }

  toggleClass(e) {
    this.toggleBackgroundClass(e.target.parentElement, e.target.checked)
  }

  toggleBackgroundClass = (targetElement, setAsActive) => {
    if(setAsActive){
      targetElement.classList.remove("bg-neutral-500", "hover:bg-neutral-700")
      targetElement.classList.add("bg-blue-500", "hover:bg-blue-500")
    }else{
      targetElement.classList.remove("bg-blue-500", "hover:bg-blue-500")
      targetElement.classList.add("bg-neutral-500", "hover:bg-neutral-700")
    }
  }
}
