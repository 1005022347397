import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "iconShow", "iconHide"]

  toggle () {
    this.iconShowTarget.classList.toggle("hidden")
    this.iconHideTarget.classList.toggle("hidden")

    const isPasswordType = this.inputTarget.getAttribute("type") == "password"
    this.inputTarget.setAttribute("type",  isPasswordType ? "text" : "password")
  }
}
