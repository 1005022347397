import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation } from "swiper"

import "swiper/css"
import "swiper/css/navigation"

export default class extends Controller {
  static targets = ["container", "leftAlignment"]
  static values = {
    config: String,
  }

  connect() {
    const targetElement = this.hasContainerTarget
      ? this.containerTarget
      : this.element

    this.swiper = new Swiper(targetElement, this.getConfig)
  }

  resize() {
    this.swiper.params.slidesOffsetBefore = this.leftOffset
    this.swiper.updateSlides()
  }

  get getConfig() {
    const baseConfig = {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    }

    switch (this.configValue) {
      case "nav-responsive-breakpoint":
        return {
          ...baseConfig,
          slidesPerView: "auto",
          spaceBetween: 16,
          slidesOffsetAfter: 16,
          slidesOffsetBefore: this.leftOffset,
        }
      default:
        return baseConfig
    }
  }

  get leftOffset() {
    return this.hasLeftAlignmentTarget
      ? this.leftAlignmentTarget.offsetLeft
      : 16
  }
}
