import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["urlInput", "iframePreview", "hintText"];

  connect() {
    this.createPreviewIframe()
  }

  createPreviewIframe(){
    const url = this.urlInputTarget.value
    if(!url){
      this.iframePreviewTarget.classList.add("hidden")
      this.hintTextTarget.classList.add("hidden")
      return
    }

    const id = this.getYoutubeVideoIDFromURL(url)
    if(id){
      this.hintTextTarget.classList.add("hidden")
      this.iframePreviewTarget.setAttribute("src", "https://www.youtube.com/embed/" + id)
      this.iframePreviewTarget.classList.remove("hidden")
    }else{
      this.iframePreviewTarget.classList.add("hidden")
      this.hintTextTarget.classList.remove("hidden")
    }
  }

  getYoutubeVideoIDFromURL(url) {
    const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matched = url.match(pattern)
    if(!matched){
      return null;
    }

    return matched[1];
  }

}
