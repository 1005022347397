import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "number"]
  counter = 1

  connect () {
    const that = this
    const tabController = this.application.getControllerForElementAndIdentifier(this.containerTarget, "tabs")

    const updateEPNumber = (fromIndex) => {
      $(this.containerTarget).find(".ep-number").slice(fromIndex).each((idx, el) => {
        that.counter = fromIndex + idx + 1
        $(el).text(that.counter)
      })
    }

    updateEPNumber(0)

    $(this.containerTarget).on("cocoon:after-insert", function (e, insertedItem) {
      that.counter++
      const numberElement = $(insertedItem).find(".ep-number")
      numberElement.text(that.counter)

      tabController.connect()
    })

    $(this.containerTarget).on("cocoon:after-remove", function (e, removedItem) {
      const numberElement = $(removedItem).find(".ep-number")
      const removedNumber = Number(numberElement.text())
      if(!isNaN(removedNumber)){
        updateEPNumber(removedNumber - 1)
      }
    })
  }

  disconnect(){
    $(this.containerTarget).off("cocoon:after-insert")
    $(this.containerTarget).off("cocoon:after-remove")
  }
}
