import { Controller } from "@hotwired/stimulus"
import select2 from "select2"

export default class extends Controller {
  static targets = ["reference"]

  connect() {
    select2()
    $(this.referenceTarget).select2({
      width: "style",
      tags: true,
    })
  }

  clear(){
    $(this.referenceTarget).val(null).trigger('change');
  }
}
