import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    visibleClass: String,
  }

  toggle(event) {
    const visibleClass = event.params.visibleClass || this.visibleClassValue

    this.containerTargets.forEach((containerTarget) => {
      this.toggleContainer(containerTarget, visibleClass)
    })
  }

  toggleContainer(containerTarget, visibleClass) {
    containerTarget.classList.toggle("hidden")

    if (visibleClass) {
      containerTarget.classList.toggle(visibleClass)
    }
  }
}
