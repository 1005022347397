import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "formContainer",
    "overlayContainer",
    "progressText",
    "progressBar",
    "cancelButton",
    "overlayProgress",
    "overlaySuccess",
  ]

  xhr = new XMLHttpRequest()
  isUserAbort = false

  connect() {
    const that = this
    this.xhr.upload.addEventListener("loadstart", function (e) {
      that.isUserAbort = false
      that.displayProgress(e, that.progressTextTarget, that.progressBarTarget)
    })

    this.xhr.upload.addEventListener("progress", function (e) {
      that.displayProgress(e, that.progressTextTarget, that.progressBarTarget)
    })

    this.xhr.addEventListener("loadend", function () {
      if (this.status == 0) {
        if (!that.isUserAbort) {
          alert("Upload fail!")
        }
        that.hideOverlay()
      } else if (this.status >= 200 && this.status <= 299) {
        that.overlayProgressTarget.classList.add("hidden")
        that.overlaySuccessTarget.classList.remove("hidden")
      } else {
        if (this.response) {
          const entries = Object.entries(this.response)
          alert(
            entries
              .map((each) => `${each[0]} : ${each[1].join(",")}`)
              .join("\n")
          )
        } else {
          alert("Unknown error!")
        }
        that.hideOverlay()
      }
    })
  }

  submit(e) {
    e.preventDefault()
    this.showOverlay()

    const formData = new FormData(this.formTarget)
    const actionUrl = this.formTarget.getAttribute("action") + ".json"
    this.xhr.open("POST", actionUrl)
    this.xhr.responseType = "json"
    this.xhr.send(formData)
  }

  cancel() {
    this.isUserAbort = true
    this.xhr.abort()
  }

  displayProgress(event, textElement, progressElement) {
    const completedPercentage = event.lengthComputable
      ? Math.floor((event.loaded / event.total) * 100)
      : 0
    textElement.innerText = completedPercentage
    progressElement.setAttribute("style", `width: ${completedPercentage}%`)
  }

  showOverlay() {
    this.formContainerTarget.classList.add("hidden")
    this.overlayContainerTarget.classList.remove("hidden")
  }

  hideOverlay() {
    this.formContainerTarget.classList.remove("hidden")
    this.overlayContainerTarget.classList.add("hidden")
  }
}
