import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nav", "container"]

  connect () {
    const nav = this.navTargets.find(nav => nav.checked)

    if (nav) {
      this.show(nav.value)
    }
  }

  change (e) {
    this.show(e.target.value)
  }

  show (id) {
    this.containerTargets.forEach(container => {
      container.classList.add("hidden")
    })

    const targetContainers = this.containerTargets.filter(container => container.id === id)

    if (targetContainers.length > 0) {
      targetContainers.forEach(targetContainer => {
        targetContainer.classList.remove("hidden")
      })
    }
  }
}
